
$(document).ready(function(){
	// Slider
	$('.order-form #slider').slider({
		animate: true,
		range: false,
		value: [$('#Orders_slider').attr('value')],
		stop: function(event, ui){
			$('#Orders_slider').attr('value', ui.value);
		}
	});

	$('.request-call-form #slider').slider({
		animate: true,
		range: false,
		value: [$('#CallRequest_slider').attr('value')],
		stop: function(event, ui){
			$('#CallRequest_slider').attr('value', ui.value);
		}
	});

	$('.faq #slider').slider({
		animate: true,
		range: false,
		value: [$('#Faq_slider').attr('value')],
		stop: function(event, ui){
			$('#Faq_slider').attr('value', ui.value);
		}
	});

	$(".accordion").click(function() {
		block = $(this).parent().find("p.answer_hidden");
		$(block).toggleClass('answer_visible',300);
		return false;
	});
});

