window.onload = function () {



    //var $sharky = document.getElementById("shark-object");
    var $sharky = $("#shark-object");
    if ($sharky.length!=1) {
        console.log("No anime");
        return ;
    }
    else {
        console.log($sharky);
    }
    var $field = document.getElementById("shark-field");
    //var $field = $(window);
    var $field = $("#shark-field");

    var rotation = 0,
        rotationX = 0,
        rotationY = 0,
        wanderTween, ignoreRollovers;
    opacityDyn = 1;

    //TweenLite.set($field, {perspective: 500} );
    TweenLite.set($sharky, {transformOrigin:"center center -50px"});



    function wander() {
        var x = (($field.width() - $sharky.width()) / 0.3) * (Math.random() * 1.8 - 0.9),
            y = (($field.height() - $sharky.height()) / 0.3) * (Math.random() * 1.4 - 0.7);
        wanderTween = TweenLite.to($sharky, 5, {x:x, y:y, ease:Power1.easeInOut, onComplete:wander});
    }

    wander();
    TweenMax.to($sharky, 3, {scaleX:0.8, scaleY:0.8, force3D:true, yoyo: true,  repeat:-1,ease: Power1.easeInOut});


    $sharky.hover(function () {
        rotation +=360;
        TweenLite.to($sharky, 4, {rotation:rotation, ease: Elastic.easeOut});

    }, function () {

    });
    
    $sharky.click(function () {


        opacityDyn -= 0.46;


        TweenLite.to($sharky,1, {opacity: opacityDyn});
        if (opacityDyn <=0.3) {
        /*    TweenMax.kill(null, $sharky);*/
            $sharky.hide();
            $("#shark-discount-modal").remodal().open();
        }

    });



    $("#franchise-block").click(function () {
        document.location.href = '/content/franchise';
    });

    $(".sale").hover(
        function() {
            TweenLite.to($(this).find('img'), 1.2, {rotationY:180, ease:Back.easeOut});
        },
        function() {
            TweenLite.to($(this).find('img'), 1.2, {rotationY:0, ease:Back.easeOut});
        }
    );


    // banner animate

    var bezierTween = new TweenMax("#anime-1", 6, {
        bezier:{
            type:"soft",
            values:[{x:150, y:0}, {x:300, y:0}, {x:500 + Math.random() *100, y:12*Math.random() + 50},
                {x:650, y:20*Math.random() + 50}, {x:900, y:0}, {x:990, y:0}],
            autoRotate:true
        },
        ease:Linear.easeNone, repeat:-1}).pause();



    var tl = new TimelineMax,
        headerText = new SplitText("#franchise-text-anim", {type:"words,chars"}),
        subText = new SplitText("#franchise-text-anim-2",{type:"words,chars"});
        headerChars = headerText.chars; //an array of all the divs that wrap each character
        subTextChars = subText.chars; //an array of all the divs that wrap each character

    TweenLite.set("#franchise-text-anim", {perspective:400});

    var $franchiseBannerDom = $(".franchise-block");
    var tlBckg = new TimelineMax($franchiseBannerDom);
    tlBckg.staggerTo($franchiseBannerDom,13,{
        'background-position-x':94 // %
    });

    var onAnimationEnd = function () {
        tl.repeatDelay(6);
        tl.repeat(1);

    }

    var animateBanner = function () {

        tl.set("#franchise-text-anim", {visibility:"visible"});
        tl.set("#franchise-text-anim-2", {visibility:"visible"});

        tl.staggerFromTo(headerChars, 2,
            {autoAlpha:0, rotation:randomNum(-360, 360),
                rotationX:randomNum(360, 360),
                rotationY:randomNum(-360, -360),
                rotationZ:randomNum(-360, 360), scale:0},
            {autoAlpha:1, rotation:0, rotationX:0, rotationY:0, rotationZ:0, scale:1},0.05,0.1, onAnimationEnd());
        tl.staggerFrom(subTextChars, 3, {opacity:0, scale:0, y:80, rotationX:180, transformOrigin:"0% 50% -50",  ease:Back.easeOut}, 0.1, "+=0.1",
            onAnimationEnd());
    }


    var randomNum = function randomNum (min, max) {
        return Math.random() * (max - min) + min;
    }
    animateBanner();


    //TweenMax.staggerFromTo( $demoText.find("span"), 0.4, {autoAlpha:0, scale:7}, {autoAlpha:1, scale:1}, 0.1, reset )


    //tl.staggerFrom(chars, 2.4, {autoAlpha:0, scale:1}, {autoAlpha:1, scale:1}, 0.1, onAnimationEnd);

    //tl.repeat(10);


}

